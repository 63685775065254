import React from 'react'
import { FormButton } from '../../styles'

const ContactForm = () => {
    return (
        <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <p>
                <label><span>Name</span><input type="text" name="name" placeholder="Name" /></label>   
            </p>
            <p>
                <label><span>Email</span><input type="email" name="email" placeholder="Email" /></label>
            </p>
            <p>
                <label><span>Phone Number</span><input type="tel" name="phonenumber" placeholder="Phone Number" /></label>
            </p>
            <p>
                <label><span>Message</span><textarea name="message" placeholder="Message"></textarea></label>
            </p>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                <FormButton type="submit" modifiers={['grey']}>Send Message</FormButton>
            </div>
        </form>
    )
}

export default ContactForm
