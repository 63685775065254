import styled from 'styled-components'
import { theme, PrimaryButton } from '../../styles'

export const HeroElm = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
    padding: 0 12px;

    @media(min-width: 700px) {
        margin: 32px 32px 0;
        min-height: 620px;
        padding: 0 40px;
    }

    @media(min-width: 1400px) {
        height: calc(100vh - 64px);
    }

    &:before {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.25);
        content: "";
    }

    h1 {
        position: relative;
        margin-bottom: 32px;
        max-width: 780px;
        color: ${theme.white};
        font-size: 2.5em;
        line-height: 1.4;
        text-shadow: 1px 1px 1px #000;
        @media(min-width: 700px) {
            font-size: 3.333em;
        }
    }

    ${PrimaryButton} {
        position: relative;
    }

    &.short {
        height: 75vh;
        margin-bottom: 30px;

        @media(min-width: 700px) {
            min-height: 600px;
        }

        ${PrimaryButton} {
            position: absolute;
            left: 50%;
            bottom: -30px;
            transform: translateX(-50%);
            z-index: 2;
        }
    }
`;

export const HeroImageWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: -1;

    .gatsby-image-wrapper {
        height: 100%;
        animation: shrink 5s infinite alternate;
    }

    @keyframes shrink {
        0% {
            background-size: 110% 110%;
        }
        100% {
            background-size: 100% 100%;
        }
    }
`;

