import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm'
import Hero from '../components/Hero'
import SectionHeading from '../components/SectionHeading'
import { theme } from '../styles'
import { Helmet } from 'react-helmet'

const ContactPage = ({props}) => {
    const data = useStaticQuery(graphql`
        {
            file(relativePath: {eq: "contact-bg.jpg"}) {
                name
                sharp: childImageSharp {
                    gatsbyImageData(formats: [AUTO, WEBP, AVIF], layout: FULL_WIDTH, placeholder: BLURRED)
                }
            }
            contactColImage: file(relativePath: {eq: "olive-tall-two.jpg"}) {
                name
                sharp: childImageSharp {
                    gatsbyImageData(formats: [AUTO, WEBP, AVIF], layout: FULL_WIDTH, placeholder: BLURRED)
                }
            }
        }
    `)

    const bgImage = data.file.sharp.gatsbyImageData
    const formImage = data.contactColImage.sharp.gatsbyImageData

    return (
        <Layout>
            <Helmet htmlAttributes={{lang: 'en'}}>
                <title>Ancient Olives | Request a Quote</title>
                <meta name="description"
                    content="No matter how difficult it is to get the trees to you, 
                    we are up to the challenge."
                />
            </Helmet>
            <Hero
                heroImage={bgImage}
                styleClass="short"
                heading={`Contact Us`}
            />
            <section
                className="top-padding bottom-padding centered"
                style={{
                    maxWidth: "830px",
                    margin: "0 auto"
                }}
                >
                <SectionHeading>
                    Let’s talk about your project! 
                </SectionHeading>
                <p className="sub-heading">
                    Fill out the form or email us at: sales@ancient-olives.com
                </p>
                <p className="sub-heading"><a href="tel:7603213537">760.321.3537</a></p>
            </section>
            <ContactWrapper>
                <div className="cols cols--twothirds">
                    <div className="col">
                        <GatsbyImage image={formImage} alt="" />
                    </div>
                    <div className="col">
                        <h3>
                            Have a question or would like a quote?<br />Fill out this form and we’ll get back to you soon.
                        </h3>
                        <ContactForm />
                    </div>
                </div>
            </ContactWrapper>
        </Layout>
    )
}

export default ContactPage

const ContactWrapper = styled.div`
    padding: 0 12px;

    @media(min-width: 700px) {
        padding: 0 40px;
    }

    form {
        margin-bottom: 40px;
    }

    .gatsby-image-wrapper {
        height: 100%;
    }

    .cols {
        flex-direction: column-reverse;
        margin-bottom: 12px;
        @media(min-width: 700px) {
            flex-direction: row;
            margin-bottom: 96px;
        }
    }

    .col {
        &:first-child {
            @media(min-width: 700px) {
                width: 45%;
            }
        }
        &:last-child {
            padding: 32px;
            background: ${theme.tan};
            
            @media(min-width: 700px) {
                padding: 64px;
                width: 55%;
            }
        }

        h3 {
            margin-bottom: 24px;
            font-size: 24px;
            line-height: 1.618;

            @media(min-width: 700px) {
                font-size: 30px;
            }
        }
    }
`;
