import React from 'react'
import {
    HeroElm,
    HeroImageWrapper
} from './HeroStyles'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrimaryButton } from '../../styles'

const Hero = ({heading, heroImage, styleClass}) => {
    return (
        <HeroElm
            className={styleClass}
        >
            <HeroImageWrapper>
                <GatsbyImage
                    objectPosition="center center"
                    image={heroImage}
                    alt="Ancient Olives"
                    placeholder="blurred"
                />
            </HeroImageWrapper>
            <h1 className="centered">{heading}</h1>
            <PrimaryButton to={`/contact/`} modifiers={['tan']}>
                Request A Quote
            </PrimaryButton>
        </HeroElm>
    )
}

export default Hero
